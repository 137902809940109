import React from 'react';
import { StaticQuery, graphql, useStaticQuery } from "gatsby";
import { noAuto } from '@fortawesome/fontawesome-svg-core';

 export const utilTemplate = () => {
    return (null);
};

export const getCatData = (n:integer=10)=>{
    const data = useStaticQuery(graphql`
    query catGroupQuery {
     allMarkdownRemark {
       group(field: frontmatter___category) {
         edges {
           node {
             frontmatter {
               title
               category
               date
             }
             fields{
                 slug
             }
           }
         }
       }
     }
 } `);
    
    const catList = ({allMarkdownRemark}, topN:integer=10) => {
        return allMarkdownRemark.group.map( ({edges}) => {
                // let topN = 5;
                let name = edges[0].node.frontmatter.category;
                let length = edges.length;
                let head = edges
                            .filter( (e,index)=>(index<topN))
                            .map( (e)=>{
                    return {title: e.node.frontmatter.title, slug: e.node.fields.slug};
                });
     
                return({name, length, head});
                }
            );
     };

    return catList(data,n);
};

