import React from 'react';
import clsx from 'clsx';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

import Avatar from '@material-ui/core/Avatar';
import SubjectIcon from '@material-ui/icons/Subject';
import ListIcon from '@material-ui/icons/List';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';

import { StaticQuery, graphql, useStaticQuery, Link, navigate } from "gatsby";
import Bio from '../Bio';
import {getCatData} from '../../utils/getData';
const config = require('../../../config');

import './menu.scss';

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

const useStylesAvatar = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
        marginBottom : 0,
      },
      borderWidth:2,
      borderLeftColor:'blue',
      borderLeftWidth: 10,
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    large: {
      width: theme.spacing(7),
      height: theme.spacing(7),
      marginBottom: 20,
    },
    img : {marginBottom:0}
  }),
);

type Anchor = 'top' | 'left' | 'bottom' | 'right';

export default function TemporaryDrawer() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [showBio, setShowBio] = React.useState(false);
  const menuFontSize = {fontSize:50}
  

  const toggleDrawer = (anchor: Anchor, open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent,
  ) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const classesAvatar = useStylesAvatar();

  const list = (anchor: Anchor) => (
       
    <div
        className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
        <div >
            <Link to="/">
                <div className="avatar-image-wrap" >
                    <img src={config.sideMenuImage} />
                </div>
                <div className="avatar-text-wrap">
                    {config.title}
                    <div className="comment" >{config.comment}</div>
                </div>
            </Link>
                
        </div>
      <Divider />
      <MenuList id="menu-list-grow"  >
      {config.menuList.map(({name,path})=>(
          <MenuItem onClick={()=>navigate(path)} >{name}</MenuItem>
      ))}
      </MenuList>

      <Divider />
      <CatList />
      <Divider />
      <MenuList>
        <MenuItem onClick={()=>setShowBio(!showBio)} > Bio </MenuItem>
        {showBio==true?(<Bio />):(<div/>)}
      </MenuList>
      
    </div>
  );

  const menuPosition = 'left';     
  return (
      <div>
    <div className="menu-topLeft-wrap">
      {/* (['left', 'right', 'top', 'bottom'] as Anchor[]).map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button>
          <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      )) */ }
      <React.Fragment key={menuPosition} >
          <Button onClick={toggleDrawer(menuPosition, true)}>
              <MenuOpenIcon style={menuFontSize} />MENU 
          </Button>
          <Drawer anchor={menuPosition} open={state[menuPosition]} onClose={toggleDrawer(menuPosition, false)}>
            {list(menuPosition)}
          </Drawer>
      </React.Fragment>
    </div>
    
    </div>
  );
}

const CatList = () => {
    const catData = getCatData(5);
    const dispCatMenu = (data)=>{
        return data.map( ({name,length,head})=>(
                    <MenuItem onClick={()=>navigate(`/categories/${name}`)}> <ListIcon /> {`${name} (${length})`}</MenuItem> 
                ) 
            );
    };

    return(
        <div>
        <Divider />
        <MenuItem> <AccountTreeIcon /> CATEGORIES</MenuItem>
        <Divider />
        <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={alert}>
            {dispCatMenu(catData)}
        </MenuList>
        </div>
    );
}