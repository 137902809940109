import * as React from 'react';


export interface GaProps {
  ga_id: string;
}
const Ga = (props: GaProps) => {
  const { ga_id } = props;
  const htmlContent =`
  <script async src="https://www.googletagmanager.com/gtag/js?id=${ga_id}"></script>
  <script>
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', '${ga_id}');
  </script>
  `;

  return (
      <div
      id='GaContainer'
      dangerouslySetInnerHTML={{__html:htmlContent}}
      />
  );
};

export default Ga;
