module.exports = {
  /** Site MetaData (Required all)*/
  title: `IT Tech Blog`,                           // (* Required)
  description: `IT Tech Blog for Data Sicentists. It deals with Data Science and machine learning, and Python. We are also concering about frontend such as javascript, react, react-native, and so on.`,          // (* Required)
  author: `Jay Lee`,                         // (* Required)
  //siteUrl: 'https://github.com/windflex-sjlee/blog',                      // (* Required)
  siteUrl : 'https://tech.richwind.co.kr',
    // ex.'https://junhobaik.github.io'
    // ex.'https://junhobaik.github.io/' << X, Do not enter "/" at the end.

  /** Header */
  profileImageFileName: 'profile.png', // include filename extension ex.'profile.jpg'
  defaultTitleImage : 'macbook.jpeg',
    // The Profile image file is located at path "./images/"
    // If the file does not exist, it is replaced by a random image.
  
  /** Home > Bio information*/
  comment: 'Data Scientist and Machine Learning Researcher with Cyber Security',
  name: 'Jay Lee',
  company: 'Jay Consulting',
  location: 'Korea',
  email: 'lee70673@gmail.com',
  website: 'https://tech.richwind.co.kr',  
  linkedin: 'https://www.linkedin.com/in/%EC%83%81%EC%9E%AC-%EC%9D%B4-ba48836b/',                                                          // ex.'https://www.linkedin.com/in/junho-baik-16073a19ab'
  facebook: '',                                                          // ex.'https://www.facebook.com/zuck' or 'https://www.facebook.com/profile.php?id=000000000000000'
  instagram: '',                                                         // ex.'https://www.instagram.com/junhobaik'
  github: 'https://github.com/sjwind',                                                            // ex.'https://github.com/junhobaik'

  /** menu items*/
  menuList : [{'name':'Home','path':'/'},{'name':'Posts', 'path':'/categories/'}, {'name':'Tags', 'path':'/tags/'}, {'name':'All Posts', 'path':'/allPosts/'}, {'name':'About','path':'/about/'}],
  sideMenuImage : 'https://source.unsplash.com/random/800x600',
  /** Post */
  enablePostOfContents: true,     // TableOfContents activation (Type of Value: Boolean. Not String)
  disqusShortname: 'techwind',            // comments (Disqus sort-name)
  enableSocialShare: true,        // Social share icon activation (Type of Value: Boolean. Not String)

  /** Optional */
  googleAnalytics: 'G-SDV0T5B5C2',     // Google Analytics TrackingID. ex.'UA-123456789-0'
  googleSearchConsole: 'ZpAlLY8NB0d_ILaY7yxA6dyOZPCDgDYsUmFjYQZ8eec', // content value in HTML tag of google search console ownership verification. ex.'w-K42k14_I4ApiQKuVPbCRVV-GxlrqWxYoqO94KMbKo'
  googleAdsenseSlot: '4903608945',   // Google Adsense Slot. ex.'5214956675'
  googleAdsenseClient: 'ca-pub-4363057419113196', // Google Adsense Client. ex.'ca-pub-5001380215831339'
    // Please correct the adsense client number(ex.5001380215831339) in the './static/ads.txt' file.
};
